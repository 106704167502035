import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useQueryParam } from "use-query-params";
import { useI18next } from 'gatsby-plugin-react-i18next';

import { RegisterLayout } from '../../components/register-layout.component';
import { verifyEmailUpdateToken } from '../../services/users.service';
import { setUser } from '../../states/authentication.slice';

export default function VerifyEmailUpdate() {
  
  const dispatch = useDispatch();
  const [ error, setError ] = useState('');
  const { t } = useTranslation();
  const { navigate } = useI18next();

  const [ email ] = useQueryParam('email');
  const [ token ] = useQueryParam('token');


  useEffect(() => {
    verifyEmailUpdateToken(email, token)
      .then(response => {
        dispatch(setUser(response.data.user));
        navigate('/dashboard/?updatedEmail=success');
      })
      .catch(e => {
        setError(e.response.data.message);
      });
  }, [ email, token ]);

  return <RegisterLayout disableSecureLayout={true} title={t('Email updated')} className="verify-confirmation" backLocation="/">
      <div className="section">
        <p>loading...</p>
      </div>
  </RegisterLayout>;
}
